import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CronModule } from '@x/common/cron';
import { LocaleModule } from '@x/common/locale';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { CollectionPointDialogComponent } from './components/collection-point-dialog/collection-point-dialog.component';
import { ParcelOptionCreateDialogComponent } from './components/parcel-option-create-dialog/parcel-option-create-dialog.component';
import { ParcelOptionUpdateDialogComponent } from './components/parcel-option-update-dialog/parcel-option-update-dialog.component';
import { ShipmentFormDialogComponent } from './components/shipment-form-dialog/shipment-form-dialog.component';
import { ShippingCategoryFormDialogComponent } from './components/shipping-category-form-dialog/shipping-category-form-dialog.component';
import { ShippingMethodDialogComponent } from './components/shipping-method-form-dialog/shipping-method-form-dialog.component';
import { ShippingScheduleDialogComponent } from './components/shipping-schedule-dialog/shipping-schedule-dialog.component';
import { ShippingSlotDialogComponent } from './components/shipping-slot-dialog/shipping-slot-dialog.component';
import { LOGISTICS_LANGUAGE } from './logistics.language';
import { CollectionPointDialogService } from './services/collection-point-dialog.service';
import { ParcelOptionDialogService } from './services/parcel-option-dialog.service';
import { ShipmentDialogService } from './services/shipment-dialog.service';
import { ShippingCategoryDialogService } from './services/shipping-category-dialog.service';
import { ShippingMethodDialogService } from './services/shipping-method-dialog.service';
import { ShippingScheduleDialogService } from './services/shipping-schedule-dialog.service';
import { ShippingSlotDialogService } from './services/shipping-slot-dialog.service';
@NgModule({
  declarations: [
    ShippingMethodDialogComponent,
    ParcelOptionUpdateDialogComponent,
    ParcelOptionCreateDialogComponent,
    ShipmentFormDialogComponent,
    ShippingCategoryFormDialogComponent,
    ShippingScheduleDialogComponent,
    ShippingSlotDialogComponent,
    CollectionPointDialogComponent,
  ],
  imports: [
    EcommerceAdminCoreModule,
    CommonModule,
    CronModule,
    LocaleModule.forChild([LOGISTICS_LANGUAGE]),
  ],
  providers: [
    ShippingMethodDialogService,
    ParcelOptionDialogService,
    ShipmentDialogService,
    ShippingCategoryDialogService,
    ShippingScheduleDialogService,
    ShippingSlotDialogService,
    CollectionPointDialogService,
  ],
  exports: [],
})
export class LogisticsSharedModule {}
