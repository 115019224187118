import { LanguageDeclaration } from '@x/common/locale';

export const LOGISTICS_LANGUAGE: LanguageDeclaration = {
  shipment: {
    state: {
      NEW: 'New',
      WAYBILL_REQUIRED: 'Waybill Required',
      PENDING: 'Pending',
      SHIPPED: 'Shipped',
      COMPLETED: 'Completed',
      CANCELLED: 'Cancelled',
    },
    actions: {
      ship: 'Ship',
    },
  },
};
